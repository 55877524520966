import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { publicUrl } from '../utils/helpers';

export default function useChangeRoute() {
  const { pathname } = useLocation();
  const history = useHistory();
  return React.useCallback(
    (route, state, search) => {
      if (pathname !== route && history) {
        history.push({ pathname: publicUrl(route), search, state });
      }
    },
    [history, pathname]
  );
}
