import React from 'react';
import useUser from '../store/selectors/useUser';
import useApiPost from '../api/useApiPost';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useSelectedVideo from '../store/selectors/useSelectedVideo';
import useSelectedDetails from '../store/selectors/useSelectedDetails';

const buildFormData = ({ uId, bId, selectedVideo, templateId, type, details = undefined }) => {
  let formData = new FormData();
  formData.set('hID', 'getTemplatePreview');
  formData.set('uID', uId);
  formData.set('bID', bId);
  formData.set('vID', selectedVideo);
  formData.set('tID', templateId);
  formData.set('type', type);
  details && formData.set('details', JSON.stringify(details));
  return formData;
};

const mapResponseToData = response => response.data.result ?? '';

export default function useTemplatePreview() {
  const { id: uId } = useUser();
  const bId = useActiveBrand();
  const selectedVideo = useSelectedVideo();
  const { templateId, ...otherDetails } = useSelectedDetails();

  const { data, handlePost, loading, error } = useApiPost({ mapResponseToData, defaultData: '' });
  const handleRefresh = React.useCallback(
    ({ includeDetails = false, onSuccess, onError }) => {
      handlePost({
        onSuccess,
        onError,
        data: buildFormData({ uId, bId, selectedVideo, templateId, details: includeDetails ? otherDetails : undefined, type: otherDetails?.type })
      });
    },
    // eslint-disable-next-line
    [uId, bId, selectedVideo, handlePost, templateId, JSON.stringify(otherDetails)]
  );

  return { templatePreview: data, getTemplatePreview: handleRefresh, loadingTemplatePreview: loading, error };
}
