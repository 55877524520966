import React from 'react';
import Footer from '../Footer';
import useUser from '../../store/selectors/useUser';
import useHandleChangeRoute from '../../utils/useHandleChangeRoute';
import useChangeRoute from '../../utils/useChangeRoute';
import { isEmpty } from '../../utils/helpers';
import useHandleLogoutUser from '../../store/actions/useHandleLogoutUser';

export default function SideBar() {
  const { name, photo } = useUser();
  const handleChangeRoute = useHandleChangeRoute();
  const changeRoute = useChangeRoute();
  const handleLogoutUser = useHandleLogoutUser();

  React.useEffect(() => {
    // User should have profile photo before sending emails
    if (isEmpty(photo) || photo.endsWith('/') || photo.endsWith('\\') || photo.indexOf('shadow.png') > -1) {
      changeRoute('/Profile');
    }
  }, [photo, changeRoute]);

  return (
    <div className='app__sidebar sidebar'>
      <div className='sidebar__logo'>
        <img src='/img/axonrx_sidebar_logo.jpeg' alt='Axonrx' />
      </div>
      <div className='app__header__profile app__header__profile--sidebar'>
        <div
          id='profileImage'
          className='app__icon app__header__icon'
          onClick={handleChangeRoute('/Profile')}
          style={{ backgroundImage: `url(${photo})` }}
        ></div>
        <span className='app__header__username'>
          {name}
          <br />
          <div onClick={handleLogoutUser} id='headerLogout' style={{ color: '#2699fb', cursor: 'pointer', textDecoration: 'underline', textAlign: 'center' }}>
            Log Out
          </div>
        </span>
      </div>
      <div className='sidebar__footer'>
        <Footer />
      </div>
    </div>
  );
}
