import React from 'react';
import useApiPost from './useApiPost';

const buildFormData = ({ uId, bId, selectedVideo, selectedContacts, selectedDetails }) => {
  let formData = new FormData();
  formData.set('hID', 'sendEmailTemplate');
  formData.set('uID', uId);
  formData.set('bID', bId);
  formData.set('vID', selectedVideo);
  formData.set('tID', 1);
  formData.set('type', selectedDetails.type);
  formData.set('recipients', JSON.stringify(selectedContacts));
  formData.set('details', JSON.stringify(selectedDetails));
  return formData;
};

export default function useSendEmail() {
  const { loading, handlePost } = useApiPost();
  const [{ disableSend }, setState] = React.useState({ disableSend: false });
  const sendEmail = React.useCallback(
    ({ uId, bId, selectedVideo, selectedContacts, selectedDetails, ...other }) => {
      handlePost({
        data: buildFormData({ uId, bId, selectedVideo, selectedContacts, selectedDetails }),
        onError: () => {
          console.error('Error sending email');
          setState(prev => ({ ...prev, disableSend: true }));
        },
        ...other
      });
    },
    [handlePost]
  );

  return { sendingEmail: loading, sendEmail, disableSend };
}
