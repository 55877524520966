import React, { Component } from "react";

class GridCell extends Component {
  constructor(props) {
    super(props);

    this.showPanel = this.showPanel.bind(this);
    this.hidePanel = this.hidePanel.bind(this);

    this.state = {
      panelHidden: true,
      buttonHidden: false
    };

    this.textLimit = 130;
  }

  showPanel() {
    this.setState({ panelHidden: false });
  }

  hidePanel() {
    this.setState({ panelHidden: true });
  }

  chop(txt) {
    let choptxt = txt.substr(0, this.textLimit);
    return (choptxt += choptxt.length === txt.length ? "" : "...");
  }

  render() {
    let panelClass, buttonClass;
    panelClass = this.state.panelHidden
      ? "app__flex-table__hiddenpanel"
      : "app__flex-table__hiddenpanel app__showpanel";
    buttonClass =
      this.props.cellText.length > this.textLimit
        ? "app__flex-table__expandbutton"
        : "app__flex-table__expandbutton app__flex-table__expandbutton--hidden";

    return (
      <div className={`app__flex-table__cell ${this.props.isSelected ? "app__flex-table__cell--selected" : "" }`} id={this.props.cellText} 
      onClick={(event) => this.props.onCellClick(event)}>
        <span
          className="app__flex-table__text"
          onClick={(event) => this.props.onCellClick(event)}
        >
          {this.chop(this.props.cellText)}
        </span>
        <button className={buttonClass} onClick={this.showPanel}>
          <img src="/img/plus-sign.svg" alt="+" />
        </button>
        <div
          className={panelClass}
        >
          <button
            className="app__flex-table__close-panel"
            onClick={this.hidePanel}
          >
            <img src="/img/close.svg" alt="-" />
          </button>
          <span>{this.props.cellText}</span>
        </div>
      </div>
    );
  }
}

export default GridCell;
