import React from 'react';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useUser from '../store/selectors/useUser';
import useApiPost from './useApiPost';

const buildFormData = ({ uId, bId, userName, key, videoName, size, type, duration, makeVideoVisible, gifBlob }) => {
  let formData = new FormData();
  formData.set('hID', 'saveVideo');
  formData.set('uID', uId);
  formData.set('bID', bId);
  formData.set('gifBlob', gifBlob);
  formData.set('name', userName);
  formData.set('videoName', videoName);
  formData.set('fileName', key);
  formData.set('size', size);
  formData.set('type', type);
  formData.set('runtime', duration);
  formData.set('saveToGallery', makeVideoVisible ? '1' : '0');
  return formData;
};

export default function useSaveVideo() {
  const { loading, handlePost } = useApiPost();
  const { id: uId, name: userName } = useUser();
  const bId = useActiveBrand();

  const saveVideo = React.useCallback(
    ({ key, videoName, size, type, duration, makeVideoVisible, gifBlob, ...other }) => {
      handlePost({
        data: buildFormData({ uId, bId, userName, key, videoName, size, type, duration, makeVideoVisible, gifBlob }),
        ...other
      });
    },
    [uId, bId, userName, handlePost]
  );

  return { saveVideo, savingVideo: loading };
}
