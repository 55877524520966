import React from 'react';
import VideoGallery from './VideoGallery';
import CustomRecorder from './CustomRecorder';
import ScriptGrid from './ScriptGrid';
import Recipients from './Recipients';
import EditTemplate from './EditTemplate';
import useUpdateSelectedVideo from '../store/actions/useUpdateSelectedVideo';
import useUpdateScriptOptions from '../store/actions/useUpdateScriptOptions';
import useActiveBrand from '../store/selectors/useActiveBrand';
import useSelectedContacts from '../store/selectors/useSelectedContacts';
import useSelectedVideo from '../store/selectors/useSelectedVideo';
import useScriptDetails from '../store/selectors/useScriptDetails';
import useSelectedDetails from '../store/selectors/useSelectedDetails';
import useSendEmail from '../api/useSendEmail';
import EmailModal from './EmailModal';
import useUser from '../store/selectors/useUser';
import EmailComposerHeader from './EmailComposerHeader';
import EmailComposerFooter from './EmailComposerFooter';
import EmailComposerSelectType from './EmailComposerSelectType';
import { defaultScriptDetails } from '../store/reducers/layout';
import EmailComposerSelectVideoType from './EmailComposerSelectVideoType';
import EmailComposerSelectGifType from './EmailComposerSelectGifType';
import useScheduleEmail from '../api/useScheduleEmail';
import useEmailModal from '../store/selectors/useEmailModal';
import useStepHistory from '../utils/useStepHistory';
import EmailComposerSelectScriptType from './EmailComposerSelectScriptType';
import ScriptManualEntry from './ScriptManualEntry';
import { isEmpty } from '../utils/helpers';

const defaultState = {
  personalized: undefined,
  animateGif: undefined
};

const getNextStep = step =>
  step === 4
    ? 9 // if video selected from video gallery, jump to the end
    : step + 1;

export default function EmailComposer() {
  const [{ animateGif, personalized }, setState] = React.useState(defaultState);

  const { id: uId } = useUser();
  const bId = useActiveBrand();
  const updateSelectedVideo = useUpdateSelectedVideo();
  const updateScriptOptions = useUpdateScriptOptions();
  const { selectedContacts } = useSelectedContacts();
  const selectedVideo = useSelectedVideo();
  const scriptDetails = useScriptDetails();
  const selectedDetails = useSelectedDetails();
  const { sendingEmail, sendEmail } = useSendEmail();
  const { schedulingEmail, scheduleEmail } = useScheduleEmail();

  const { step, onNext, onReset, handleBack } = useStepHistory({ getNextStep });

  const { handleOpenEmailModal } = useEmailModal();
  const handleReset = React.useCallback(() => {
    setState(defaultState);
    onReset();
    updateSelectedVideo(null);
    updateScriptOptions(defaultScriptDetails);
  }, [onReset, setState, updateSelectedVideo, updateScriptOptions]);

  const onSendEmail = React.useCallback(() => {
    !sendingEmail &&
      sendEmail({
        uId,
        bId,
        selectedVideo,
        selectedContacts,
        selectedDetails,
        onSuccess: result => {
          const results = Array.isArray(result?.data?.result) ? result?.data?.result : [result?.data?.result]; //Ensure an array of results
          const successEmails = results.filter(result => result.toLowerCase().includes('=> success') && !result.toLowerCase().includes('ok => success'));
          const failedEmails = results.filter(result => result.toLowerCase().includes('=> fail'));
          console.log('success', { successEmails, failedEmails, results, result });
          handleOpenEmailModal(true, 'send', successEmails, failedEmails)();
        }
      });
  }, [uId, bId, selectedVideo, selectedContacts, selectedDetails, sendingEmail, sendEmail, handleOpenEmailModal]);

  const onScheduleEmail = React.useCallback(
    ({ timeZone, dateTime }) => {
      !schedulingEmail &&
        scheduleEmail({
          uId,
          bId,
          selectedVideo,
          selectedContacts,
          selectedDetails,
          timeZone,
          dateTime,
          onSuccess: handleOpenEmailModal(true, 'schedule')
        });
    },
    [uId, bId, selectedVideo, selectedContacts, selectedDetails, schedulingEmail, scheduleEmail, handleOpenEmailModal]
  );

  const handleSelectType = React.useCallback(
    (personalized = false) =>
      () => {
        onNext({ nextStep: 2 });
        setState(prev => ({ ...prev, personalized }));
      },
    [onNext, setState]
  );

  const handleSelectVideoType = React.useCallback(
    type => () => {
      if (type === 'select') {
        // Removed selected video in case user went back
        updateSelectedVideo(null);
        updateScriptOptions(defaultScriptDetails);
        onNext({ nextStep: 4 });
      } else {
        onNext({ nextStep: 5 }); // Create video
      }
    },
    [onNext, updateSelectedVideo, updateScriptOptions]
  );

  const handleSelectGifType = React.useCallback(
    gifType => () => {
      onNext({ nextStep: 8 });
      setState(prev => ({ ...prev, animateGif: gifType === 'animated' }));
    },
    [setState, onNext]
  );

  const handleCompleteRecord = React.useCallback(
    newId => {
      updateSelectedVideo(newId);
      onNext({ nextStep: 9 });
    },
    [updateSelectedVideo, onNext]
  );

  const isActiveStep = React.useCallback(steps => (Array.isArray(steps) ? steps.find(s => s === step) : steps === step), [step]);

  const hideBack = isActiveStep([1, 8, 10]);

  const hideNext =
    isActiveStep([1, 3, 5, 7, 8, 9, 10]) ||
    (step === 2 && selectedContacts.length === 0) ||
    (step === 4 && selectedVideo === null) ||
    (step === 6 &&
      scriptDetails.type !== 'manual' &&
      (scriptDetails.intro === '' || scriptDetails.contentA === '' || scriptDetails.contentB === '' || scriptDetails.closing === '')) ||
    (step === 6 &&
      scriptDetails.type === 'manual' &&
      isEmpty(scriptDetails.intro) &&
      isEmpty(scriptDetails.contentA) &&
      isEmpty(scriptDetails.contentB) &&
      isEmpty(scriptDetails.closing));

  const isPending = sendingEmail || schedulingEmail;

  return (
    <>
      <EmailModal isPending={isPending} onClose={handleReset} />
      <EmailComposerHeader step={step} onBack={handleBack} onSendEmail={onSendEmail} onScheduleEmail={onScheduleEmail} disabled={isPending} />
      <div className='composerContainer'>
        {isActiveStep(1) && <EmailComposerSelectType onSelect={handleSelectType} />}
        {isActiveStep(2) && <Recipients singleSelect={personalized} />}
        {isActiveStep(3) && <EmailComposerSelectVideoType onSelect={handleSelectVideoType} />}
        {isActiveStep(4) && <VideoGallery canSelect={true} canDelete={false} />}
        {isActiveStep(5) && <EmailComposerSelectScriptType onNext={onNext} />}
        {isActiveStep(6) && <>{scriptDetails?.type === 'select' ? <ScriptGrid personalized={personalized} /> : <ScriptManualEntry />}</>}
        {isActiveStep(7) && <EmailComposerSelectGifType onSelect={handleSelectGifType} />}
        {isActiveStep(8) && <CustomRecorder animateGif={animateGif} onBack={handleBack} onComplete={handleCompleteRecord} canSaveToGallery={!personalized} />}
        {isActiveStep([9, 10]) && (
          <div id='templateEditContainer' className={`templateEditContainer`}>
            <EditTemplate step={step} selectedVideo={selectedVideo} onNext={onNext} />
          </div>
        )}
      </div>
      <EmailComposerFooter step={step} onBack={handleBack} onNext={onNext} hideBack={hideBack} hideNext={hideNext} disableNext={isPending} />
    </>
  );
}
