import React from 'react';
import useUser from '../store/selectors/useUser';
import useApiPost from '../api/useApiPost';
import useSelectedDetails from '../store/selectors/useSelectedDetails';

const buildFormData = ({ uId, templateId }) => {
  let formData = new FormData();
  formData.set('hID', 'getTemplateOptions');
  formData.set('uID', uId);
  formData.set('tID', templateId);  
  return formData;
};

const mapResponseToData = response => {
  return {
    subjects: response.data.result.subjects,
    subtexts: response.data.result.subtexts,
    greetings: response.data.result.greetings,
    intros: response.data.result.intros,
    closings: response.data.result.closings,
    onSubject: response.data.result.subjects[0],
    onGreeting: response.data.result.greetings[0],
    onIntro: response.data.result.intros[0]
  };
};

const defaultTemplateOptions = {
  subjects: [],
  subtexts: [],
  greetings: [],
  intros: []
};

export default function useTemplateOptions() {
  const { id: uId } = useUser();
  const { templateId } = useSelectedDetails();

  const { data, handlePost, loading, error } = useApiPost({ mapResponseToData, defaultData: defaultTemplateOptions });

  const handleRefresh = React.useCallback(
    ({ onSuccess, onError }) => handlePost({ onSuccess, onError, data: buildFormData({ uId, templateId }) }),
    [uId, templateId, handlePost]
  );

  return { templateOptions: data, loadingTemplateOptions: loading, error, handleRefresh };
}
