import React from 'react';
import SendEmailButtons from './SendEmailButtons';

const steps = [
  { label: 'Select Recipients', isSelected: step => [1, 2].find(s => s === step) },
  { label: 'Create/Select Video', isSelected: step => [3, 4, 5, 6, 7, 8].find(s => s === step) },
  { label: 'Customize Email', isSelected: step => [9].find(s => s === step) },
  { label: 'Preview Email', isSelected: step => [10].find(s => s === step) }
];

export default function EmailComposerHeader({ step, onBack, onNext, onSendEmail, onScheduleEmail, disabled }) {
  return (
    <>
      <section className='app__subheader'>
        <nav className='app__subheader__nav' aria-label='Breadcrumb'>
          <ul className='app__subheader__list'>
            {steps.map(({ label, isSelected }, i) => (
              <li key={`step${i}`} className={`app__subheader__crumb ${isSelected(step) ? 'app__subheader__crumb--selected' : ''}`}>
                <div className='app__bignumber'></div>
                <span className='app__subheader__crumb-text'>{label}</span>
              </li>
            ))}
          </ul>
        </nav>
      </section>
      {step === 10 && <SendEmailButtons onBack={onBack} onNext={onNext} onSendEmail={onSendEmail} onScheduleEmail={onScheduleEmail} disabled={disabled} />}
    </>
  );
}
