import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="app__footer">
        <div className="app__footer__container">
          <div className="app__footer__logo"></div>
          <p className="app__footer__smalltext">
            &copy; 2021 Axon.<br/>
            XX-XXX-XXXX 08/21
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
