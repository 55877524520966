import React from 'react';
import config from 'react-global-configuration';
import axios from 'axios';

const defaultMapResponseToData = response => response.data; // Maps the server response to data field, by default use response.data but can be overridden to whatever applies.
const defaultDataValue = [];

// Generic hook for re-useable api post logic
export default function useApiPost({ mapResponseToData = defaultMapResponseToData, defaultData = defaultDataValue } = {}) {
  const [{ loading, data, error }, setState] = React.useState({ loading: false, data: defaultData, error: undefined });
  const handlePost = React.useCallback(
    ({ onSuccess, onError, ...other }) => {
      setState(prev => ({ ...prev, loading: true, data: defaultData }));
      axios({
        method: 'post',
        url: config.get('apiUrl') + 'index.php',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data'
        },
        ...other
      })
        .then(function (response) {
          setState(prev => ({ ...prev, data: mapResponseToData(response), loading: false }));
          onSuccess && onSuccess(response);
        })
        .catch(function (response) {
          console.error('Error api post', { response, other });
          setState(prev => ({ ...prev, data: defaultData, loading: false, error: response.data }));
          onError && onError(response);
        });
    },
    [mapResponseToData, defaultData]
  );

  return { data, error, loading, handlePost, setState };
}
