import React from 'react';

export default function EmailComposerFooter({ step, onBack, hideBack, onNext, hideNext, disableNext }) {
  return (
    <div className={`app__pagenavigation clearfix`}>
      <div className={`app__pagenavigation__icon prev ${hideBack ? 'hidden' : ''}`} onClick={onBack}>
        <i className='fa fa-caret-left'></i> BACK
      </div>
      <div
        className={`app__pagenavigation__icon next ${hideNext ? 'hidden' : ''} ${step === 9 ? 'send' : ''} ${disableNext ? 'disabled' : ''}`}
        onClick={onNext}
      >
        NEXT <i className='fa fa-caret-right'></i>
      </div>
    </div>
  );
}
