import React from 'react';
import GifRecorder from './GifRecorder';
import VideoRecorder from './VideoRecorder';
import useSaveVideo from '../api/useSaveVideo';
import MediaRecorderGate from './layout/MediaRecorderGate';

export default function CustomRecorder({ animateGif, canSaveToGallery, onBack, onComplete }) {
  const [{ hasGif, gifBlob }, setState] = React.useState({ hasGif: false, gifBlob: null });
  const { saveVideo } = useSaveVideo();

  const handleSaveVideo = React.useCallback(
    ({ key, videoName, makeVideoVisible, videoBlob = {}, duration, onSuccess }) => {
      const { size, type } = videoBlob; // Extract necessary metaData for API storage call
      saveVideo({
        key,
        videoName,
        makeVideoVisible,
        size,
        type,
        duration,
        gifBlob,
        onSuccess: response => {
          onSuccess && onSuccess(response.data.result); // child callback
          onComplete(response.data.result); // parent callback
        }
      });
    },
    [onComplete, saveVideo, gifBlob]
  );

  return (
    <div className='app__attachments'>
      <MediaRecorderGate onBack={onBack}>
        {!hasGif ? (
          <GifRecorder animateGif={animateGif} onBack={onBack} closeGifRecorder={gifBlob => setState(prev => ({ ...prev, hasGif: true, gifBlob }))} />
        ) : (
          <VideoRecorder onBack={onBack} canSaveToGallery={canSaveToGallery} onSave={handleSaveVideo} />
        )}
      </MediaRecorderGate>
    </div>
  );
}
