import {
  RESET_STATE,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  UPDATE_SELECTED_CONTACTS,
  UPDATE_SELECTED_VIDEO,  
  UPDATE_SCRIPT_OPTIONS,
  UPDATE_LAYOUT
} from '../constants/action-types';

export function resetState(payload) {
  return { type: RESET_STATE, payload };
}

export function loginUser(payload) {
  return { type: LOGIN, payload };
}

export function logoutUser(payload) {
  return { type: LOGOUT, payload };
}

export function updateUser(payload) {
  return { type: UPDATE_USER, payload };
}

export function updateSelectedContacts(payload) {
  return { type: UPDATE_SELECTED_CONTACTS, payload };
}

export function updateSelectedVideo(payload) {
  return { type: UPDATE_SELECTED_VIDEO, payload };
}

export function updateScriptOptions(payload) {
  return { type: UPDATE_SCRIPT_OPTIONS, payload };
}

export function updateLayout(payload) {
  return { type: UPDATE_LAYOUT, payload };
}
