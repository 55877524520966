import React from 'react';
import useChangeRoute from '../utils/useChangeRoute';
import { isEmpty } from '../utils/helpers';
import useEmailModal from '../store/selectors/useEmailModal';

export default function EmailModal({ isPending, onClose }) {
  const changeRoute = useChangeRoute();
  const { open, type, successEmails = [], failedEmails = [], openEmailModal } = useEmailModal();
  const handleClose = React.useCallback(
    route => () => {
      !isEmpty(route) && changeRoute(route);
      openEmailModal(false);
      onClose && onClose();
    },
    [onClose, openEmailModal, changeRoute]
  );

  const unVerifiedSender = failedEmails.filter(failedEmail => failedEmail.toLowerCase().includes('not verified'));
  const unVerifiedRecipients = failedEmails.filter(failedEmail => !failedEmail.toLowerCase().includes('not verified'));
  console.log('email modal', { successEmails, failedEmails, unVerifiedSender, unVerifiedRecipients });

  return (
    <div className={`${open ? '' : 'hidden'}`}>
      <div className='app__modal' id='email-sent-modal'>
        <div className='app__modal__container'>
          {isPending && (
            <div className='record__complete'>
              <div className='record__complete__header'>{type === 'schedule' ? 'Scheduling email...' : 'Sending email...'}</div>
              <div className='record__complete__body'>
                <img src='/img/loading.gif' alt='Loading' className='loading' />
              </div>
            </div>
          )}

          {!isPending && (
            <div className='record__complete'>
              <div className='record__complete__header'>
                <h4>Email {type === 'schedule' ? 'Schedule' : 'Send'} Confirmation</h4>
              </div>
              <div className='record__complete__body'>
                <div style={{ width: '45%', float: 'left' }}>
                  <img className='record__complete__big-icon' src='img/icon--big-camera.png' alt='complete' />
                </div>
                <div style={{ width: '55%', float: 'right' }}>
                  {type === 'schedule' ? (
                    <p className='app__modal__text'>Congrats, your email has been scheduled.</p>
                  ) : successEmails.length > 0 ? (
                    <p className='app__modal__text'>Congrats, your email was successfully sent!</p>
                  ) : (
                    <p className='app__modal__text'>One or more emails failed to send.</p>
                  )}
                  <button onClick={handleClose('/SendEmail')} className='app__button'>
                    SEND ANOTHER EMAIL
                  </button>
                  <button onClick={handleClose('/Activity')} className='app__button'>
                    REVIEW ACTIVITY LOG
                  </button>
                </div>
              </div>
              <div style={{ paddingLeft: 16 }}>
                {unVerifiedSender.length > 0 && (
                  <div style={{ color: 'red' }}>
                    Sender email is unverified: {unVerifiedSender[0]}
                    <br />
                  </div>
                )}
                {unVerifiedRecipients.length > 0 && (
                  <div style={{ color: 'red' }}>
                    {unVerifiedRecipients.length} unverified recipient emails.
                    <br />
                  </div>
                )}
                {successEmails.length > 0 && (
                  <div style={{ color: 'white' }}>
                    Successfully sent {successEmails.length} email{successEmails.length > 1 ? 's' : ''}.
                    <br />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
