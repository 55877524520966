import React from 'react';
import useApiPost from './useApiPost';

const buildFormData = ({ uId, bId, selectedVideo, selectedContacts, selectedDetails, dateTime, timeZone }) => {
  let formData = new FormData();
  // TODO: Connect API call for scheduling email

  dateTime = new Date((typeof dateTime === "string" ? new Date(dateTime) : dateTime).toLocaleString("en-US", {timeZone: timeZone}));
  dateTime = Math.round(dateTime.getTime()/1000);

  formData.set('hID', 'sendEmailTemplate');
  formData.set('uID', uId);
  formData.set('bID', bId);
  formData.set('vID', selectedVideo);
  formData.set('tID', 1);
  formData.set('type', selectedDetails.type);
  formData.set('recipients', JSON.stringify(selectedContacts));
  formData.set('details', JSON.stringify(selectedDetails));
  formData.set('timeToSend', dateTime);

  return formData;
};

export default function useScheduleEmail() {
  const { loading, handlePost } = useApiPost();
  const [{ disableSchedule }, setState] = React.useState({ disableSchedule: false });
  const scheduleEmail = React.useCallback(
    ({ uId, bId, selectedVideo, selectedContacts, selectedDetails, dateTime, timeZone, ...other }) => {
      handlePost({
        data: buildFormData({ uId, bId, selectedVideo, selectedContacts, selectedDetails, dateTime, timeZone }),
        onError: () => {
          console.error('Error scheduling email');
          setState(prev => ({ ...prev, disableSchedule: true }));
        },
        ...other
      });
    },
    [handlePost]
  );

  return { schedulingEmail: loading, scheduleEmail, disableSchedule };
}
