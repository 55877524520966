import React from 'react';
import useLoginUser from '../store/actions/useLoginUser';
import { isEmpty } from '../utils/helpers';
import useLogin from '../api/useLogin';
import useResetPassword from '../api/useResetPassword';
import useSendResetEmail from '../api/useSendResetEmail';

const validateLogin = values => {
  const errors = {};
  if (isEmpty(values.email)) errors['email'] = 'Email is required';
  if (isEmpty(values.password)) errors['password'] = 'Password is required';
  return errors;
};

const validateResetPassword = values => {
  const errors = {};
  if (isEmpty(values.email)) errors['email'] = 'Email is required';
  if (isEmpty(values.password)) errors['password'] = 'Password is required';
  if (isEmpty(values.password)) errors['confirmPassword'] = 'Confirm Password is required';
  if (values.password !== values.confirmPassword) errors['password'] = 'Passwords must match';
  if (values.password !== values.confirmPassword) errors['confirmPassword'] = 'Passwords must match';
  return errors;
};

const validateSendLink = values => {
  const errors = {};
  if (isEmpty(values.email)) errors['email'] = 'Email is required';
  return errors;
};

const LinkOnWay = ({ linkSent, error, handleSendLink, onBack }) => (
  <div id='linkOnWay'>
    <h1 className='app__login-panel__title'>Your Link is on Its Way!</h1>
    <p>
      Please check your inbox for a link to reset your password.
      <br />
      <br />
      Didn't get a link?
    </p>
    <button onClick={handleSendLink} className='admin__button'>
      Resend
    </button>
    <div style={{ marginTop: 8, color: 'white', cursor: 'pointer', textDecoration: 'underline', textAlign: 'center', width: '100%' }} onClick={onBack}>
      Back to Login
    </div>
    {linkSent > 1 && <div className='error'>{`We have sent your link again! (${linkSent} times total)`}</div>}
    {error ? <div className='error'>{error}</div> : <span></span>}
  </div>
);

const views = {
  login: { header: 'Welcome', subHeader: 'Please log in to your account' },
  forgotPassword: { header: 'Forgot Password' },
  resetPassword: { header: 'Reset Password' },
  linkOnWay: { header: 'Your Link is on Its Way!', subHeader: 'Please check your inbox for a link to reset your password.' }
};

export default function SignIn() {
  const [{ view, error, errors, email, linkSent, password, confirmPassword, resetToken }, setState] = React.useState({
    view: 'login',
    error: null,
    email: '',
    password: '',
    confirmPassword: '',
    resetToken: '',
    resetEmail: null,
    errors: {},
    linkSent: 0
  });

  const loginUser = useLoginUser();
  const { login, loading } = useLogin();
  const { resetPassword, resetting } = useResetPassword();
  const { sendResetEmail, sending } = useSendResetEmail();

  const inProgress = loading || resetting || sending;

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('s') === 'reset_password_email' && urlParams.get('token') !== null) {
      setState(prev => ({ ...prev, view: 'resetPassword', error: '', errors: {}, resetToken: urlParams.get('token') }));
    }
  }, []);

  const handleChange = React.useCallback(id => event => setState(prev => ({ ...prev, [id]: event?.target?.value, error: '', errors: {} })), [setState]);
  const handleChangeView = React.useCallback(view => () => setState(prev => ({ ...prev, view, error: '', errors: {} })), [setState]);

  const handleLogin = React.useCallback(() => {
    const errors = validateLogin({ email, password });
    if (Object.keys(errors).length > 0) {
      setState(prev => ({ ...prev, errors }));
    } else {
      login({
        email,
        password,
        onSuccess: response => {
          localStorage.setItem('access_token', response.data.jwt); // Set JWT in local storage for service calls
          localStorage.setItem('expire_at', response.data.expire_at);
          loginUser(response.data);
        },
        onError: response => {
          setState(prev => ({ ...prev, error: response.data.code }));
        }
      });
    }
  }, [login, loginUser, email, password, setState]);

  const handleSendLink = React.useCallback(() => {
    const errors = validateSendLink({ email });
    console.log({ errors });
    if (Object.keys(errors).length > 0) {
      setState(prev => ({ ...prev, errors }));
    } else {
      console.log('Sending reset email', email);
      sendResetEmail({
        email,
        onSuccess: () => {
          setState(prev => ({
            ...prev,
            view: 'linkOnWay',
            linkSent: prev.linkSent + 1,
            errors: {}
          }));
        },
        onError: response => {
          setState(prev => ({ ...prev, error: response.data.code }));
        }
      });
    }
  }, [email, sendResetEmail]);

  const handleResetPassword = React.useCallback(() => {
    const errors = validateResetPassword({ email, password, confirmPassword });
    if (Object.keys(errors).length > 0) {
      setState(prev => ({ ...prev, errors }));
    } else {
      resetPassword({
        email,
        password,
        confirmPassword,
        resetToken,
        onSuccess: () => {
          handleLogin();
        },
        onError: response => {
          setState(prev => ({ ...prev, error: response.data.code }));
        }
      });
    }
  }, [resetPassword, email, password, confirmPassword, resetToken, setState, handleLogin]);

  const onKeyPressed = React.useCallback(
    event => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    },
    [handleLogin]
  );

  const isActiveView = React.useCallback(views => (Array.isArray(views) ? (views.find(v => v === view) ? true : false) : views === view), [view]);

  const activeView = views[view];

  return (
    <div className='repcast app admin'>
      <div className='landscapeOverlay'>
        <p>Small mobile devices are not supported.</p>
      </div>
      <div className='app__container'>
        <div className='admin__column--left'>
          <img className='signIn__logo' src='/img/repcastlogo_white@2x.png' alt='REPCast' />
        </div>
        <div className='admin__column--right'>
          <div className='app__login-panel'>
            {isActiveView('linkOnWay') ? (
              <LinkOnWay linkSent={linkSent} error={error} handleSendLink={handleSendLink} onBack={handleChangeView('login')} />
            ) : (
              isActiveView(['login', 'resetPassword', 'forgotPassword']) && (
                <div id='login'>
                  <div className='admin__error-div admin__error-div--welcome'>{error}</div>
                  <h1 className='app__login-panel__title'>{activeView.header}</h1>
                  {activeView.subHeader && <p>{activeView.subHeader}</p>}
                  {isActiveView(['login', 'forgotPassword', 'resetPassword']) && (
                    <label>
                      <p>Email Address</p>
                      <input
                        className='admin__input-text'
                        type='text'
                        name='username'
                        value={email}
                        onChange={handleChange('email')}
                        onKeyPress={onKeyPressed}
                        disabled={inProgress}
                      ></input>
                      {errors.email && <div style={{ color: 'red' }}>{errors.email}</div>}
                    </label>
                  )}
                  {isActiveView(['login', 'resetPassword']) && (
                    <label>
                      <p>{view === 'resetPassword' ? 'New Password' : 'Password'}</p>
                      <input
                        className='admin__input-text'
                        name='password'
                        type='password'
                        value={password}
                        onChange={handleChange('password')}
                        onKeyPress={onKeyPressed}
                        disabled={inProgress}
                      ></input>
                      {errors.password && <div style={{ color: 'red' }}>{errors.password}</div>}
                    </label>
                  )}
                  {isActiveView(['resetPassword']) && (
                    <label>
                      <p>Confirm New Password</p>
                      <input
                        className='admin__input-text'
                        name='confirmPassword'
                        type='password'
                        value={confirmPassword}
                        onChange={handleChange('confirmPassword')}
                        onKeyPress={onKeyPressed}
                        disabled={inProgress}
                      ></input>
                      {errors.confirmPassword && <div style={{ color: 'red' }}>{errors.confirmPassword}</div>}
                    </label>
                  )}
                  {isActiveView(['forgotPassword', 'resetPassword']) && (
                    <div style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline', textAlign: 'right' }} onClick={handleChangeView('login')}>
                      Back to Login
                    </div>
                  )}
                  {isActiveView(['login']) && (
                    <>
                      <div
                        style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline', textAlign: 'right' }}
                        onClick={handleChangeView('forgotPassword')}
                      >
                        Forgot Password?
                      </div>
                      <button onClick={handleLogin} className='admin__button'>
                        Login
                      </button>
                    </>
                  )}
                  {isActiveView('forgotPassword') && (
                    <button onClick={handleSendLink} className='admin__button'>
                      Send Link
                    </button>
                  )}
                  {isActiveView('resetPassword') && (
                    <button onClick={handleResetPassword} className='admin__button'>
                      ok
                    </button>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
