import React, { Component } from "react";

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 300,
    };
  }

  componentDidMount = () => {
    this.interval = setInterval(() => {

      if (this.state.count === 1) {
        clearInterval(this.interval);
        this.props.onCountEnd();
      } else {
        this.setState({ count: this.state.count - 1 });
      }
    }, 10);
  };

  render() {
    let cr = (this.state.count / 3  * (31.42 / 100)) + " 31.42";
    return <div className="countdown">
      <svg id="countdownSVG" height="220" width="220" viewBox="0 0 20 20" transform="rotate(270)">
        <circle r="10" cx="10" cy="10"fill="#199dde"/>
        <circle r="5" cx="10" cy="10" fill="#199dde"
        stroke="#243e76"
        strokeWidth="10"
        strokeDasharray={cr} />
      </svg>
      <div className="countdown__numbers">{Math.ceil(this.state.count/100)}</div>
      <div className="countdown__text">SECONDS</div>
    </div>;
  }
}

export default CountDown;
