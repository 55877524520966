import React, { Component } from "react";
import config from "react-global-configuration";
import axios from "axios";
import { connect } from "react-redux";
import Recipients from "../components/Recipients";
import PhotoCapture from "../components/PhotoCapture";
import { logoutUser, updateUser } from "../store/actions/index";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.toggleInputs = this.toggleInputs.bind(this);
    this.state = {
      error: "",
      updateError: "",
      showInputs: false,
      showProfileInputs: false,
      showContactSection: false,
      activeSection: ""
    };
  }

  componentDidMount = () => {
    // Set up form data
    document.getElementById("userName").value = this.props.user.name;
    document.getElementById("userTitle").value = this.props.user.title;
    document.getElementById("userPhone").value = this.props.user.phone;
    document.getElementById("userEmail").value = this.props.user.email;

    if(this.props.user.photo.indexOf("shadow.png") > -1 || this.props.user.photo === '') {
      this.setState({activeSection: 'photo'});
    }
  };

  toggleInputs() {
    this.setState({ showInputs: !this.state.showInputs });
  };

  toggleProfileInputs() {
    this.setState({ showProfileInputs: !this.state.showProfileInputs });
  };

  handleShowSection = (name) => {
    this.setState({ activeSection: name });
  };

  handleLogout = (event) => {
    // Remove JWT 
    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_at");
    localStorage.removeItem("tmpUser");

    this.props.logoutUser({});
  };

  handleUpdateUser = (event) => {
    let name = document.getElementById("userName").value;
    let phone = document.getElementById("userPhone").value;

    if (name === "" || phone === "") {
      this.setState({ updateError: "All fields are required." });
    } else {
      const that = this;
      const apiUrl = config.get("apiUrl");
      let bodyFormData = new FormData();
      const token = localStorage.getItem("access_token");
      bodyFormData.set("hID", "updateUser");
      bodyFormData.set("uID", this.props.user.id);
      bodyFormData.set("fullname", name);
      bodyFormData.set("phone", phone);

      let newDetails = {
        name: name,
        phone: phone,
        photo: this.props.user.photo // keep same for now
      };

      axios({
        method: "post",
        url: apiUrl + "index.php",
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if (
            response.data.status === "false" ||
            response.data.status === "fail"
          ) {
            that.setState({ updateError: response.data.code });
          } else {
            that.setState({ updateError: "User successfully updated." });

            that.props.updateUser(newDetails);
            that.toggleProfileInputs();
          }
        })
        .catch(function (response) {
          that.setState({ updateError: "Error updating user" });
        });
    }
  };

  handleChangePassword = (event) => {
    let oldPw = document.getElementById("oldPassword").value.trim();
    let newPw = document.getElementById("newPassword").value.trim();
    let newPw2 = document.getElementById("newPasswordRepeat").value.trim();

    if (oldPw === "" || newPw === "" || newPw2 === "") {
      this.setState({ error: "All password fields are required." });
    } else if (newPw !== newPw2) {
      this.setState({ error: "Repeated new password does not match." });
    } else {
      const that = this;
      const apiUrl = config.get("apiUrl");
      let bodyFormData = new FormData();
      const token = localStorage.getItem("access_token");
      bodyFormData.set("hID", "changePass");
      bodyFormData.set("uID", this.props.user.id);
      bodyFormData.set("oldPw", oldPw);
      bodyFormData.set("newPw", newPw2);

      axios({
        method: "post",
        url: apiUrl + "index.php",
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if (
            response.data.status === "false" ||
            response.data.status === "fail"
          ) {
            that.setState({ error: response.data.code });
          } else {
            that.setState({ error: "Password successfully updated." });
          }
        })
        .catch(function (response) {
          that.setState({ error: "Error updating password" });
        });
    }
  };

  handlePhotoUpdated = (photo) => {
    let newDetails = {
      name: this.props.user.name,
      phone: this.props.user.phone,
      photo: photo
    };
    this.props.updateUser(newDetails);
  }

  render() {
    let editProfileBoolean = this.state.showProfileInputs;
   let editProfileClass = this.state.showProfileInputs
      ? "block"
      : "none";

      return (
        <section className="app__profile">
        <div className="app__profile__modal"
          style={{
                display:
                  (this.state.activeSection === "password") || editProfileBoolean ? "block" : "none",
              }}>
        </div>
          <div className="app__profile__left" 
          style={{
                display:
                  this.state.activeSection === "photo" || this.state.activeSection === "contacts" ? "none" : "block",
              }}>
          {/*
          // Reset password
          */}
          <div className="app__profile__change-password"
          style={{
                display:
                  this.state.activeSection === "password" ? "block" : "none",
              }}>
            <h2>Change Your Password</h2>
      
              <div
                className="app__profile__hide-show app__profile__hide-show--hide"
                onClick={this.toggleInputs}
              ></div>
              <label className="app__profile__password-label">
              <p>Old Password</p>
                <input
                  className="admin__input-text"
                  type="password"
                  name="oldPassword"
                  id="oldPassword"
                ></input>
              </label>
              <label className="app__profile__password-label">
              <p>New Password</p>
                <input
                  className="admin__input-text"
                  type="password"
                  name="newPassword"
                  id="newPassword"
                ></input>
              </label>
              <label className="app__profile__password-label">
              <p>Confirm Password</p>
                <input
                  className="admin__input-text"
                  type="password"
                  name="newPasswordRepeat"
                  id="newPasswordRepeat"
                ></input>
              </label>
              <button
                className="app__profile__ok app__profile__ok--cancel"
                onClick={(event) => this.handleShowSection("")}
              >
               CANCEL
              </button>
              <button
                className="app__profile__ok"
                onClick={(event) => this.handleChangePassword()}
              >
                OK
              </button>
              <div style={{marginTop: 16}}>
                <div className="app__profile__error-div">{this.state.error}</div>
                <div className="app__profile__error-div app__profile__error-div--top">
                  {this.state.updateError}
                </div>  
              </div>
          </div>
          {/*
          // Main Profile Panel
          */}
          <div className="app__profile__main-panel"
          style={{
                display:
                  this.state.activeSection === "password" ? "none" : "block",
              }}>
              <div  style={{
                display:
                  editProfileClass === "block" ? "none" : "block",
              }}>
          
            <div
              className="app__profile__big-icon"
              style={{ backgroundImage: `url(${this.props.user.photo})` }}
            ></div>
            <div className="app__profile__name">{this.props.user.name}</div>
            <div className="app__profile__title">{this.props.user.title}</div>
            <div><i className="fa fa-phone"></i> {this.props.user.phone}</div>
            <div><i className="fa fa-envelope" aria-hidden="true"></i> {this.props.user.email}</div>

            <button className="app__profile__edit-button" onClick={(event) => this.toggleProfileInputs()}>Edit Profile</button>
            <div className="app__profile__error-div">{this.state.error}</div>
            <div className="app__profile__error-div app__profile__error-div--top">
              {this.state.updateError}
            </div>
            </div>
            <div className="app__profile__inputlist" style={{display: editProfileClass}}>
              <h2>Edit Your Profile</h2>
              {/*<button className='app_close-profile__button app__button' onClick={(event) => this.toggleProfileInputs()}>
                <i className="fa fa-close"></i>
              </button>*/}
              <label className="app__profile__input-text__label clearfix">
                <div className="app__profile__input-text__label-text">Name</div>
                <input
                  className="admin__input-text"
                  type="text"
                  name="userName"
                  id="userName"
                ></input>
              </label>
              <label className="app__profile__input-text__label clearfix">
                <div className="app__profile__input-text__label-text">Phone</div>
                <input
                  className="admin__input-text"
                  type="text"
                  name="userPhone"
                  id="userPhone"
                ></input>
              </label>
              <label className="app__profile__input-text__label clearfix">
                <div className="app__profile__input-text__label-text" disabled>
                  Title
                </div>
                <input
                  className="admin__input-text"
                  type="text"
                  name="userTitle"
                  id="userTitle"
                  disabled
                ></input>
              </label>
              <label className="app__profile__input-text__label clearfix">
                <div className="app__profile__input-text__label-text" disabled>
                  Email
                </div>
                <input
                  className="admin__input-text"
                  type="text"
                  name="userEmail"
                  id="userEmail"
                  disabled
                ></input>
              </label>
              <button
                className="app__profile__ok app__profile__ok--cancel"
                onClick={(event) => this.toggleProfileInputs()}
              >
               CANCEL
              </button>
              <button
                className="app__profile__ok"
                onClick={(event) => this.handleUpdateUser()}
              >
                update
              </button>
            </div>
            </div>
          </div>
          <div className="app__profile__right"
          style={{
                display:
                  this.state.activeSection === "photo" || this.state.activeSection === "contacts" ? "none" : "block",
              }}>

            <div className="app__profile__button-container">
              <button
                className={`app__profile__button ${
                  this.state.activeSection === "photo" ? "active" : ""
                }`}
                onClick={(event) => this.handleShowSection("photo")}
              >
                Take Profile Photo
              </button>
              <button
                className={`app__profile__button ${
                  this.state.activeSection === "contacts" ? "active" : ""
                }`}
                onClick={(event) => this.handleShowSection("contacts")}
              >
                Update Contacts
              </button>
              <button className={`app__profile__button ${
                  this.state.activeSection === "password" ? "active" : ""
                }`} onClick={(event) => this.handleShowSection("password")}>Change Password</button>
                <button className="app__profile__button" onClick={(event) => this.props.handleSendEmailClick()}>Send an Email</button>
              <button
                className="app__profile__button"
                onClick={(event) => this.handleLogout()}
              >
              Log Out
              </button>
            </div>

          </div>

          <div className="app__profile__bottom-area">
            <div
              className="app__profile__activity-container" 
              style={{
                display:
                  this.state.activeSection === "photo" ? "block" : "none",
              }}
            >
            <button
                className="app__button app__button--back app__profile__button--top-right"
                onClick={(event) => this.handleShowSection("")}
              ><i className="fa fa-caret-left"></i> Back to profile</button>
              {/*
              // Take profile photo
              */}
              <h4 className="app__simple-crumb">Profile <i className="fa fa-caret-right" aria-hidden="true"></i> User Photo</h4>
              <div className="app__profile__user-photo">
                <PhotoCapture onPhotoUpdate={this.handlePhotoUpdated}/>
              </div>
            </div>
  
            <div
              className="app__profile__contacts-container"
              style={{
                display:
                  this.state.activeSection === "contacts" ? "block" : "none",
              }}
            >
            <button
                className="app__button app__button--back app__profile__button--top-right"
                onClick={(event) => this.handleShowSection("")}
              ><i className="fa fa-caret-left"></i> Back to Profile</button>
              <h4 className="app__simple-crumb">Profile <i className="fa fa-caret-right" aria-hidden="true"></i> Update Contacts</h4>

              <div className="app__profile__contacts">
                <Recipients
                  profileMode={true}
                  doReset={true}
                  uId={this.props.user.id}
                  repRegion={this.props.user.region}
                  bId={this.props.bId}
                  handleRecipientsUpdated={this.handleRecipientsUpdated}
                ></Recipients>
              </div>
            </div>
          </div>
        </section>
      );
  }
}

const mapStateToProps = state => {
  return { user: state.layout.user, bId: state.layout.activeBrand };
};
function mapDispatchToProps(dispatch) {
  return {
    logoutUser: isLoggedIn => dispatch(logoutUser(isLoggedIn)),
    updateUser: user => dispatch(updateUser(user))    
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
