import React, { Component } from "react";
import GridCell from "../components/GridCell";
import config from "react-global-configuration";
import axios from "axios";
import { connect } from "react-redux";
import { updateScriptOptions } from "../store/actions/index";

class ScriptGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      templateId: 1, // todo: hardcoding for now
      gridItems: [],
      intro: "",
      contentA: "",
      contentB: "",
      closing: "",
      totalRows: 0,
      personalized: false
    };
  }

  componentDidMount(prevProps) {
    this.getScriptGrid(this.state.personalized);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.personalized !== this.state.personalized && this.props.personalized !== undefined) {
      this.setState({personalized: this.props.personalized}, () => {
        this.getScriptGrid(this.props.personalized);
      })
    }
  }

  getScriptGrid = (isPersonalized = false) => {
    console.log("getScriptGrid");
    let that = this;
    let apiUrl = config.get("apiUrl");
    let token = localStorage.getItem("access_token");
    let bodyFormData = new FormData();
    bodyFormData.set("hID", "getScriptGrid");
    bodyFormData.set("uID", this.props.user.id);
    bodyFormData.set("tID", this.state.templateId);
    bodyFormData.set("bID", this.props.bId);

    axios({
      method: "post",
      url: apiUrl + "index.php",
      data: bodyFormData,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        // unselect all
        const selectedEls = document.querySelectorAll(
          ".app__flex-table__cell--selected"
        );
        for (const el of selectedEls) {
          el.classList.remove("app__flex-table__cell--selected");
        }

        let result = isPersonalized ? response.data.personalized.result : response.data.general.result;

        let maxLen = result["intro"].length;
        if (result["contentA"].length > maxLen) {
          maxLen = result["contentA"].length;
        }
        if (result["contentB"].length > maxLen) {
          maxLen = result["contentB"].length;
        }
        if (result["closing"].length > maxLen) {
          maxLen = result["closing"].length;
        }

        that.setState({
          gridItems: result,
          intro: "",
          contentA: "",
          contentB: "",
          closing: "",
          totalRows: maxLen,
        });
      })
      .catch(function (response) {
        console.log("Error getting script grid");
      });
  };

  handleSelect = (event, col, row) => {
    let el = event.target;

    if (el.tagName === "IMG") {
      return;
    }

    if (el.tagName === "SPAN") {
      el = event.target.parentElement;
    }

    if (el.id === "") {
      return;
    }

    // Do nothing if this item is already selected
    if (this.state[col] === el.id) {
      return;
    }

    if (this.state[col] !== "") {
      document
        .getElementById(this.state[col])
        .classList.remove("app__flex-table__cell--selected");
    }
    el.classList.add("app__flex-table__cell--selected");

    this.setState({ [col]: el.id }, () => {
      this.checkGridSelected();
    });
  };

  checkGridSelected = () => {
    let details = {
      intro: this.state.intro,
      contentA: this.state.contentA,
      contentB: this.state.contentB,
      closing: this.state.closing,
    };
    this.props.updateScriptOptions(details);
  };

  createGrid = () => {
    let rows = [];
    let titles = ["intro", "contentA", "contentB", "closing"];

    for (let i = 0; i < this.state.totalRows; i++) {
      let children = [];

      for (let j = 0; j < titles.length; j++) {
        let title = titles[j];
        let items = this.state.gridItems;
        if (items[title][i]) {
          children.push(
            <GridCell
              key={"cell-" + i + "-" + j}
              id={items[title][i]}
              onCellClick={(event) => this.handleSelect(event, title, i)}
              cellText={this.state.gridItems[title][i]}
              isSelected={this.props.scriptDetails[title] === items[title][i]}
            />
          );
        } else {
          children.push(<GridCell key={"cell-" + i + "-" + j} cellText="" />);
        }
      }

      rows.push(
        <div key={"gridrow-" + i} className="app__flex-table__row">
          {children}
        </div>
      );
    }

    return rows;
  };

  render() {
    return (
      <div className="app__container script-grid">
      <p><b>Select</b> a cell from <b>each</b> of the four columns<br/>to create your custom script.</p>
      <div id="emailGridContainer" className="app__script-grid__container">
        {this.state.gridItems["intro"] &&
          this.state.gridItems["contentA"] &&
          this.state.gridItems["contentB"] &&
          this.state.gridItems["closing"] && (
            <div>
              <div className="app__flex-table__row--header">
                <div className="app__flex-table__cell--header">Column One<br/><small>(Intro)</small></div>
                <div className="app__flex-table__cell--header">Column Two<br/><small>(Topic)</small></div>
                <div className="app__flex-table__cell--header">Column Three<br/><small>
                  (Call to Action)
                </small></div>
                <div className="app__flex-table__cell--header">Column Four<br/><small>(Closing)</small></div>
              </div>

              {this.createGrid()}
            </div>
          )}
      </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { user: state.layout.user, bId: state.layout.activeBrand, scriptDetails: state.layout.scriptDetails };
};
function mapDispatchToProps(dispatch) {
  return {
    updateScriptOptions: (options) => dispatch(updateScriptOptions(options))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ScriptGrid);
