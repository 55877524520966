import React from 'react';
import { useLocation } from 'react-router';
import { isEmpty } from '../../utils/helpers';
import useHandleChangeRoute from '../../utils/useHandleChangeRoute';

const tabs = [
  { label: 'Send Email', route: '/', altRoute: '/SendEmail' },
  { label: 'Video', route: '/Videos' },
  { label: 'Activity', route: '/Activity' }
];

export default function Header() {
  const handleChangeRoute = useHandleChangeRoute();
  const { pathname } = useLocation();

  return (
    <header className='app__header clearfix'>
      <div className='app__header__container'>
        <div className='app__header__profile'></div>
        <div className='app__header__tabs'>
          {tabs.map(({ label, route, altRoute }) => {
            const isActive = pathname === route || (!isEmpty(altRoute) && pathname === altRoute);
            return (
              <div key={label} className={`app__header__tab${isActive ? ' active' : ''}`} onClick={handleChangeRoute(route)}>
                {label}
              </div>
            );
          })}
        </div>
      </div>
    </header>
  );
}
