import React from 'react';
import CustomSelect from '../components/CustomSelect';
import useUpdateSelectedDetails from '../store/actions/useUpdateSelectedDetails';
import useTemplateOptions from '../api/useTemplateOptions';
import useTemplatePreview from '../api/useTemplatePreview';
import useSelectedDetails from '../store/selectors/useSelectedDetails';
import { defaultSelectedDetails } from '../store/reducers/layout';
import { isEmpty } from '../utils/helpers';

const setIFrameContent = response => {
  let el = document.getElementById('previewHtmlEdit');
  el.contentDocument.body.innerHTML = response.data.result;
  let links = el.contentDocument.body.getElementsByTagName('a');
  for (var i = 0, len = links.length; i < len; i++) {
    links[i].onclick = function () {
      console.log('blocked link in edit preview');
      return false;
    };
  }
  el.contentDocument.body.scrollTo(0, 0);
};

export default function EditTemplate({ step, onNext }) {
  const updateSelectedDetails = useUpdateSelectedDetails();
  const { templateOptions, handleRefresh } = useTemplateOptions();
  const { getTemplatePreview } = useTemplatePreview();

  const selectedDetails = useSelectedDetails();

  const activeTab = selectedDetails?.type;

  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    updateSelectedDetails(defaultSelectedDetails); // Reset options on mount
    // Load initial templates options from server and set default selected details
    handleRefresh({
      onSuccess: response => {
        updateSelectedDetails(prev => ({
          ...prev,
          subject: response.data.result.subjects[0],
          subtext: response.data.result.subtexts[response.data.result.subjects[0]],
          greeting: response.data.result.greetings[0],
          intro: response.data.result.intros[0],
          close: response.data.result.closings[0],
          customSubject: '',
          customText: ''
        }));
      }
    });
  }, [updateSelectedDetails, handleRefresh]);

  React.useEffect(() => {
    getTemplatePreview({ includeDetails: step === 10, onSuccess: setIFrameContent });
  }, [activeTab, step, getTemplatePreview]); // Generate template preview on step change and store in iFrame

  const handleSelect = React.useCallback((type, val) => updateSelectedDetails(prev => ({ ...prev, [type]: val })), [updateSelectedDetails]);

  const handleChange = React.useCallback(
    type => event => {
      const value = event?.target?.value;
      setErrors({});
      handleSelect(type, value);
    },
    [handleSelect]
  );

  const handleTabClick = type => updateSelectedDetails(prev => ({ ...prev, type }));

  const { customText, customSubject } = selectedDetails;

  const handleCustomPreviewClick = React.useCallback(() => {
    const newErrors = {};
    if (isEmpty(customSubject)) {
      newErrors['customSubject'] = 'Template subject is required.';
    }
    if (isEmpty(customText)) {
      newErrors['customText'] = 'Template text is required.';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      onNext && onNext();
    }
  }, [onNext, customText, customSubject]);

  return (
    <div>
      {step === 10 && selectedDetails.type === 'Options' && (
        <>
          <span className='app__email-preview__subject'>
            <b>Subject:</b> {selectedDetails.subject}
          </span>
        </>
      )}
      {step === 10 && selectedDetails.type === 'Custom' && (
        <>
          <span className='app__email-preview__subject'>
            <b>Subject:</b> {selectedDetails.customSubject}
          </span>
        </>
      )}
      <div className='app__email-preview'>
        <iframe title='Preview' width='625px' height='865px' className={'templatePreview' + (step === 10 ? '--centered' : '')} id='previewHtmlEdit' />
      </div>
      <section className='app__select-topic' key={`template-1`}>
        {step === 9 && (
          <div className='app__email-options'>
            <span className={`app__email-option-tab ${activeTab === 'Options' ? 'active' : ''}`} onClick={() => handleTabClick('Options')}>
              Options
            </span>
            <span className={`app__email-option-tab ${activeTab === 'Custom' ? 'active' : ''}`} onClick={() => handleTabClick('Custom')}>
              Custom
            </span>

            <div className={`app__custom-email ${activeTab === 'Custom' ? 'active' : ''}`}>
              <input
                type='text'
                value={selectedDetails?.customSubject}
                placeholder='Subject'
                className='app__custom-subject'
                onChange={handleChange('customSubject')}
              />
              <textarea
                rows='10'
                value={selectedDetails?.customText}
                placeholder='Template Copy'
                className='app__custom-text'
                onChange={handleChange('customText')}
              ></textarea>
              {Object.keys(errors).map(k => (
                <div id={k} key={k} style={{ color: 'red', paddingTop: 8, paddingBottom: 8 }}>
                  {errors[k]}
                  <br />
                </div>
              ))}
              <button className='app__select-topic__button app__button' onClick={handleCustomPreviewClick}>
                Preview My Email
              </button>
            </div>

            <div className={`app__dropdowns clearfix ${activeTab === 'Options' ? 'active' : ''}`}>
              <label>Subject Line</label>
              <CustomSelect type='subject' value={selectedDetails.subject} options={templateOptions.subjects} onSelect={handleSelect} />
              <div className='unselectable'>
                <label>Greeting</label>
                <CustomSelect type='greeting' value={selectedDetails.greeting} options={templateOptions.greetings} onSelect={handleSelect} />
                <label>Introduction</label>
                <CustomSelect type='intro' value={selectedDetails.intro} options={templateOptions.intros} onSelect={handleSelect} />
                <button className='app__select-topic__button app__button' onClick={onNext}>
                  Preview My Email
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
