import * as React from 'react';
import { Route, Switch } from 'react-router';
import { publicUrl } from '../../utils/helpers';
import Activity from '../pages/Activity';
import Profile from '../pages/Profile';
import Videos from '../pages/Videos';
import SendEmail from '../pages/SendEmail';

const Routes = () => (
  <Switch>
    <Route exact path={publicUrl('/Videos')} component={Videos} />
    <Route exact path={publicUrl('/Activity')} component={Activity} />
    <Route exact path={publicUrl('/Profile')} component={Profile} />
    <Route path={'/SendEmail'} component={SendEmail} />
    <Route path={'/'} component={SendEmail} />
  </Switch>
);

export default Routes;
