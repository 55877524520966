import { evalFunc } from '../../utils/helpers';
import {
  RESET_STATE,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
  UPDATE_SELECTED_CONTACTS,
  UPDATE_SELECTED_VIDEO,
  UPDATE_SCRIPT_OPTIONS,
  UPDATE_LAYOUT
} from '../constants/action-types';

export const defaultScriptDetails = {
  type: undefined,
  intro: '',
  contentA: '',
  contentB: '',
  closing: ''
};

export const defaultSelectedDetails = {
  type: 'Options',
  templateId: 1,
  subject: '',
  subtext: '',
  greeting: '',
  intro: '',
  close: '',
  customSubject: '',
  customText: '',
};

const initialState = {
  isLoggedIn: false,
  isEmailModalOpen: false,
  emailModalType: 'send',
  successEmails: [],
  failedEmails: [],
  user: {},
  activeBrand: 1,
  selectedVideo: null,
  selectedContacts: [],
  selectedEmails: {}, // Info for mapping selectedContacts selected email field
  selectedDetails: defaultSelectedDetails,
  scriptDetails: defaultScriptDetails
};

function layoutReducer(state = initialState, action) {
  console.log({ state });
  if (action.type === RESET_STATE) {
    return {
      ...state,
      selectedVideo: null,
      selectedContacts: [],
      selectedDetails: defaultSelectedDetails
    };
  }

  if (action.type === LOGIN) {
    let newUser = {
      email: action.payload.email,
      id: action.payload.id,
      name: action.payload.name,
      phone: action.payload.phone,
      photo: action.payload.photo,
      region: action.payload.region,
      title: action.payload.title
    };
    return {
      ...state,
      user: newUser,
      isLoggedIn: true
    };
  }

  if (action.type === LOGOUT) {
    return {
      ...state,
      user: {},
      isLoggedIn: false
    };
  }

  if (action.type === UPDATE_USER) {
    let newUser = Object.assign({}, state.user, {
      name: action.payload.name,
      phone: action.payload.phone,
      photo: action.payload.photo
    });
    return {
      ...state,
      user: newUser
    };
  }

  if (action.type === UPDATE_SELECTED_CONTACTS) {
    return {
      ...state,
      selectedContacts: action.payload
    };
  }

  if (action.type === UPDATE_SELECTED_VIDEO) {
    console.log('UPDATE_SELECTED_VIDEO ' + action.payload);
    return {
      ...state,
      selectedVideo: action.payload
    };
  }

  if (action.type === UPDATE_SCRIPT_OPTIONS) {
    return {
      ...state,
      scriptDetails: {
        ...state.scriptDetails,
        ...evalFunc(action.payload)
      }
    };
  }

  if (action.type === UPDATE_LAYOUT) {
    return {
      ...state,
      ...evalFunc(action.payload, state)
    };
  }

  return state;
}

export default layoutReducer;
