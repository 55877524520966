import React from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from '.';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router';

export default function useLoginUser() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  return React.useCallback(
    payload => {
      dispatch(push(pathname)); // This clears any search/query params and reloads the previous route on refresh
      dispatch(loginUser(payload));
    },
    [pathname, dispatch]
  );
}
