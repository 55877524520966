import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import DatePicker from 'react-datepicker';
import Loader from 'react-loader-spinner';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { isEmpty } from '../utils/helpers';
import useActivities from '../api/useActivities';

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }
    },
    useSortBy,
    usePagination
  );

  return data?.length === 0 ? (
    <span className="app__activity-no-results">You have no activity between these dates.</span>
  ) : (
    <>
      <table className='app__activity__header-table' {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={column.isSorted ? (column.isSortedDesc ? '-sort-asc' : '-sort-desc') : ''}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className='pagination'>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page {pageIndex + 1} of {pageOptions.length}{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

const columns = [
  {
    Header: 'Date Sent',
    accessor: 'dateSent',
    sortType: 'basic'
  },
  {
    Header: 'Recipient',
    accessor: 'recipient',
    sortType: 'basic'
  },
  {
    Header: 'Subject Line',
    accessor: 'subject',
    sortType: 'basic'
  },
  {
    Header: 'Attachments',
    accessor: 'attachments',
    sortType: 'basic'
  },

  {
    Header: 'Activity',
    accessor: 'activity',
    sortType: 'basic'
  }
];

const getDefaultState = () => {
  const endDate = new Date();
  return {
    startDate: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 6),
    endDate,
    searchTerm: ''
  };
};

export default function ActivityTable() {
  const [{ searchTerm, startDate, endDate }, setState] = React.useState(getDefaultState());

  const { data, loading, handleRefresh } = useActivities();
  const handleChange = React.useCallback(id => value => setState(prev => ({ ...prev, [id]: value })), [setState]);
  const handleSearchChange = React.useCallback(e => handleChange('searchTerm')(e?.target?.value), [handleChange]);

  const filtered = isEmpty(searchTerm)
    ? data
    : data.filter(({ recipient, dateSent, subject, attachments }) =>
        [recipient, dateSent, subject, attachments].reduce(
          (isMatch, searchText) => isMatch || (!isEmpty(searchText) && searchText.toLowerCase().includes(searchTerm.toLowerCase())),
          false
        )
      );

  return (
    <div>
      {loading && (
        <div className='activity-loader'>
          <Loader type='ThreeDots' color='#199dde' height={100} width={100} timeout={300000} />
        </div>
      )}

      {!loading && (
        <div className='app__activity-header'>
          <div className='app__activity-actions'>
            <h4>ACTIVITY LOG</h4>
            <div className='app__activity-actions__row'>
              <DatePicker
                id='activityStartDate'
                className='app__small-input'
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                onChange={handleChange('startDate')}
              />
              <span className='divider'> to </span>
              <DatePicker
                id='activityEndDate'
                className='app__small-input'
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                onChange={handleChange('endDate')}
              />
              <button className='app__button app__button--flat activity-action-submit' onClick={handleRefresh}>
                go
              </button>
              <div className='app__activity__search-div'>
                <span>
                  <i className='fa fa-search'></i> Search:
                </span>
                <input
                  type='text'
                  id='activitySearch'
                  placeholder='Search...'
                  className='app__small-input app__small-input__long app__activity__search'
                  onChange={handleSearchChange}
                ></input>
              </div>
            </div>
          </div>
          <Table className='app__activity-table' columns={columns} data={filtered} />
        </div>
      )}
    </div>
  );
}
