import config from "react-global-configuration";

export default function initConfig(env = "local") {
  if (env === "local") {
    config.set(
      {
        apiUrl: "http://repcaststudio:8888/api/",
        outlookAppId: "74885008-d0db-4953-9687-8801435147c2",
        outlookRedirectUri: "http://localhost:3000",
      },
      { freeze: false }
    );
  } else if (env === "development") {
    // repcast.axonrx.com
    config.set(
      {
        apiUrl: "/web/api/",
        outlookAppId: "e4dbbd96-dbab-44a8-94ed-32cbc86c88e1",
        outlookRedirectUri: "https://repcast.axonrx.com",
      },
      { freeze: false }
    );
  } else if (env === "production") {
    // REPCAST.com
    config.set(
      {
        apiUrl: "/web/api/",
        outlookAppId: "7211f85a-3584-402c-95d4-e4bd6274d938",
        outlookRedirectUri: "https://www.repcast.com",
      },
      { freeze: false }
    );
  } else {
    throw new Error("Unknown environment, failed to initialize config.");
  }
}
