import React, { Component, useEffect, useRef } from 'react';
import { ReactMediaRecorder } from '../components/ReactMediaRecorder';
import CountDown from '../components/CountDown';
import { connect } from 'react-redux';

const GifPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);
  if (!stream) {
    return null;
  }
  return <video ref={videoRef} autoPlay />;
};

class GifRecorder extends Component {
  constructor(props) {
    super(props);

    this.videoRef = React.createRef();

    this.state = {
      gifBlob: null,
      gifRecording: false,
      gifPreview: false,
      gifAccepted: false,
      showGifIntro: true,
      showCounter: false,
      gifImage: null,
      gifSupported: true,
      gifLoading: false
    };
  }

  saveGif = () => {
    this.setState({
      gifPreview: false,
      gifAccepted: true
    });
  };

  componentDidMount = () => {
    if (!window.gifshot.isWebCamGIFSupported()) {
      this.setState({ gifSupported: false });
    }
  };

  render() {
    let { gifRecording, gifPreview, gifAccepted, showGifIntro, showCounter, gifImage, gifSupported, gifLoading } = this.state;

    const { animateGif } = this.props;

    const gifComplete = gifImage !== null && gifAccepted;

    const headingText = animateGif ? 'Record your GIF' : 'Take your photo';

    return (
      <div className='app__modal recorder__modal'>
        <div className={`app__modal__container ${gifComplete ? 'modal__complete' : ''}`}>
          <div>
            {gifImage === null && !gifAccepted && !gifComplete && (
              <h3>
                <i className='fa fa-circle'></i>
                {headingText}
              </h3>
            )}
            {gifImage !== null && !gifComplete && <h3>Review your {animateGif ? 'gif' : 'photo'}</h3>}
          </div>
          {gifImage === null && gifRecording && !this.state.showCounter && (
          <div className="app__recorder__record-light"><i className="fa fa-circle"></i></div>
          )}
          <ReactMediaRecorder
            video={true}
            audio={false}
            render={({ previewStream, width, height }) => (
              <div style={{ height: '100%' }}>
                {gifImage === null && (
                  <div className='app__modal__screen'>
                    <GifPreview stream={previewStream} />
                    {!gifRecording && !gifPreview && !gifLoading && (
                      <div className='app__modal__back'>
                        <button className="app__button app__button--video-back" onClick={this.props.onBack}><i className="fa fa-repeat"></i> Start Over</button>
                      </div>
                    )}
                    {gifSupported && !gifRecording && !gifPreview && !gifLoading && (
                      <div className='action_record'>
                        <button
                          onClick={e => {
                            this.setState({
                              showCounter: true,
                              showGifIntro: false,
                              gifRecording: true
                            });
                          }}
                        >
                          <i className='fa fa-circle'></i>
                          Record
                        </button>
                      </div>
                    )}

                    {showGifIntro && (
                      <div id='recordIntro' className='record_intro'>
                        {gifSupported && (
                          <div>
                            <h4>{animateGif ? 'Record your intro animated gif' : 'Take your intro photo'}</h4>
                            <p>
                              Get comfortable and try to center yourself well in the video frame. When you are ready, click the RECORD button and a 3-second
                              timer will pop up.
                              <br />
                              <br />
                              When the timer finishes counting down, the {animateGif ? 'recording will begin' : 'photo will be taken'}. When you are done, you
                              will have the chance to review, re-take or accept your new {animateGif ? 'animated GIF' : 'photo'}!
                            </p>
                          </div>
                        )}

                        {!gifSupported && (
                          <div>
                            <h4>Webcam Recording Unsupported</h4>
                            <p>
                              Your browser does not support gif recording. Please update or switch to a supported browser and try again: Firefox 17+, Chrome
                              21+, Opera 18+.
                            </p>
                          </div>
                        )}
                      </div>
                    )}

                    {showCounter && (
                      <CountDown
                        onCountEnd={() => {
                          this.setState({ showCounter: false });
                          let that = this;

                          if (animateGif) {
                            setTimeout(() => {
                              this.setState({ gifRecording: false, gifLoading: true });
                            }, 3000);

                            window.gifshot.createGIF({ numFrames: 30, gifWidth: width, gifHeight: height }, function (obj) {
                              if (!obj.error) {
                                that.setState({
                                  gifImage: obj.image,
                                  gifLoading: false
                                });
                              } else {
                                alert('Error capturing gif');
                              }
                            });
                          } else {
                            // TODO: can we match the video aspect ratio?
                            window.gifshot.takeSnapShot({ gifWidth: width, gifHeight: height }, function (obj) {
                              if (!obj.error) {
                                that.setState({
                                  gifImage: obj.image
                                });
                              }
                            });
                          }
                        }}
                      />
                    )}
                  </div>
                )}

                {gifLoading && <div className='gif_loader'></div>}

                {gifImage !== null && !gifComplete && (
                  <div className='gif__preview'>
                    {!gifComplete && <img src={gifImage} alt='Gif Preview' />}

                    {!gifComplete && gifPreview && (
                      <button
                        className='back_review_button'
                        onClick={e => {
                          this.setState({ gifPreview: false });
                        }}
                      >
                        Back to review
                      </button>
                    )}

                    {!gifComplete && !gifPreview && (
                      <div className='action_buttons gif__review-buttons'>
                        <button
                          className='gif__review-button'
                          onClick={e => {
                            this.setState({ gifPreview: true });
                          }}
                        >
                          <i className='fa fa-play'></i>
                          <br />
                          Preview
                        </button>{' '}
                        <button
                          className='gif__review-button'
                          onClick={e => {
                            this.saveGif();
                          }}
                        >
                          <i className='fa fa-check'></i>
                          <br />
                          Accept
                        </button>{' '}
                        <button
                          className='gif__review-button'
                          onClick={e => {
                            this.setState({
                              gifImage: null,
                              showCounter: true,
                              showGifIntro: false,
                              gifRecording: true
                            });
                          }}
                        >
                          <i className='fa fa-circle'></i>
                          <br />
                          Retake
                        </button>
                      </div>
                    )}
                  </div>
                )}
                {gifComplete && (
                      <div className='record__complete record__complete--gif'>
                      <div className='record__complete__header'>
                        <h4>Your Animated GIF is complete</h4>
                        </div>
                        <img className='record__complete__image animated_gif_preview' src={gifImage} alt='Gif Preview' />
                        <div className="record__complete__body">
                          Great job!
                          <br />
                          <br /> You are now ready to record your full-length video!
                        </div>
                        <div className="record__complete__button-row">
                          <button className="app__button app__button--modal app__button--accent" onClick={this.props.onBack}>Start Over</button>
                          <button
                          className="app__button app__button--modal"
                            onClick={e => {
                              this.props.closeGifRecorder(gifImage);
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    )}
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { user: state.layout.user, bId: state.layout.activeBrand };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(GifRecorder);
