import * as React from 'react';
import Routes from './components/router/routes';
import { ConnectedRouter } from 'connected-react-router';
import Layout from './components/layout/Layout';
import AuthGate from './components/layout/AuthGate';
import './App.scss';

export default function App({ history }) {
  return (
    <ConnectedRouter history={history}>
      <AuthGate>
        <Layout>
          <Routes />
        </Layout>
      </AuthGate>
    </ConnectedRouter>
  );
}
